<template>
<div class="container-fluid bg">
    <form>
        <v-img class="center" src="@/assets/logo2.svg" width="200"/>
        
        <label for="username">{{$t('login-section.username-placeholder')}} :</label>
        <v-text-field name="login" :value="form.body.email"  v-model="form.body.email" type="text"></v-text-field>

        <label for="password">{{$t('login-section.Password')}} :</label>
        <v-text-field name="password" :value="form.body.password"  v-model="form.body.password" :append-icon="value ? 'visibility' : 'visibility_off'" @click:append="() => (value = !value)" :type="value ? 'password' : 'text'"></v-text-field>
        
       
        <el-select v-model="lang" placeholder="Select" @change="isAllLocations()">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>

        <v-btn depressed color="#007FFF" class="mt-4" @click="loginDefault" style="border-radius:0px">{{$t('login-section.login')}}</v-btn>
    </form>

    <base-material-snackbar v-model="snackbar" type="info"  color="#EE9D06" v-bind="{ top: true,  right: true }">
            <div class="subtitle-1 font-weight-light" > Echec de connexion: vérifier vos coordonées et votre accès à internet </div>
    </base-material-snackbar>

</div>
</template>

<script>

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const menuOptions = [
    {
        id: 'edit',
        label: 'Edit',
        icon: 'edit',
        secondaryText: 'Ctrl+E'
    },
    {
        id: 'duplicate',
        label: 'Duplicate',
        icon: 'content_copy',
        secondaryText: 'Ctrl+D'
    },
    {
        id: 'share',
        label: 'Share',
        icon: 'share',
        secondaryText: 'Ctrl+Shift+S',
        disabled: true
    },
    {
        type: 'divider'
    },
    {
        id: 'delete',
        label: 'Delete',
        icon: 'delete',
        secondaryText: 'Del'
    }
];

export default {

    data() {

        return{
            
            title: this.$t('login-section.title'),
            username: this.$t('login-section.username'),
            username_placeholder: this.$t('login-section.username-placeholder'),
            password_placeholder: this.$t('login-section.password-placeholder'),
            Password: this.$t('login-section.Password'),
            remember: this.$t('login-section.remember'),
            login: this.$t('login-section.login'),
            warning: this.$t('login-section.warning'),

            step: 1,
            checked: false,
            options: [
                {
                    value: 'fr',
                    label: 'Français'
                },
                {
                    value: 'en',
                    label: 'Anglais'
                }
            ],

            lang : 'fr',

            value: String,
            form: {
                body: {
                    email: '',
                    password: '',
                    type:'web'
                },
                remember: false,
                fetchUser: true,
                staySignedIn: false,
                errors: {}
            },

            snackbar: false,
        }
    },

    created(){
        this.initLang(this.$i18n.locale);
    },

    methods: {

        errors(res) {
            this.form.errors = Object.fromEntries(res.data.errors.map(item => [item.field, item.msg]));                
        },

        loginDefault() {
            NProgress.start();
                this.$auth
                    .login({
                        // body: this.form.body, // VueResource
                        data: this.form.body, // Axios
                        remember: this.form.remember,
                        fetchUser: this.form.fetchUser,
                        staySignedIn: this.form.staySignedIn,
                    }).then(null, (res) => {
                        this.snackbar = true;
                    }
                );
            NProgress.done();
        },

        isAllLocations(){
            if (this.lang == 'fr') {
                this.$i18n.locale = 'fr' 
            }else{
                this.$i18n.locale = 'en' 
            }      
            
            console.log(this.$i18n.locale);
        },

        initLang(param){
            if (param == 'fr') { 
                this.lang = 'fr';
            }
            else{ 
                this.lang = 'en';
            }
        }


    }

}
</script>

<style scoped>
    .bg {
        width: 100;
        height: 100vh;
    }
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
    form{
        height: 520px;
        width: 400px;
        background-color: rgba(255,255,255,0.13);
        position: absolute;
        transform: translate(-50%,-50%);
        top: 50%;
        left: 50%;
        border-radius: 0px;
        backdrop-filter: blur(10px);
        border: 2px solid rgba(255,255,255,0.1);
        box-shadow: 0 0 40px rgba(8,7,16,0.6);
        padding: 1% 2%;
    }
    form *{
        font-family: 'Poppins',sans-serif;
        letter-spacing: 0.5px;
        outline: none;
        border: none;
        align-items: center;
        width: 100%;
    }
    
    label{
        color: #080710;
        display: block;
        margin-top: 30px;
        font-size: 16px;
        font-weight: 500;
    }
    
    button{
        margin-top: 20px;
        width: 100%;
        padding: 15px 0;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
        cursor: pointer;
    }
</style>